export const SN_UTIL = 'util'
export const UTIL_REJECT_REASONS_UPDATED = SN_UTIL + '/REJECT_REASONS_UPDATED'
export const UTIL_STATUS_UPDATED = SN_UTIL + '/STATUS_UPDATED'
export const UTIL_PAYMENT_METHODS_UPDATED = SN_UTIL + '/PAYMENT_METHODS_UPDATED'
export const UTIL_FACILITY_TYPE_UPDATED = SN_UTIL + '/FACILITY_TYPE_UPDATED'
export const UTIL_PARTY_NAMES_UPDATED = SN_UTIL + '/PARTY_NAMES_UPDATED'
export const UTIL_CANCEL_REASONS_UPDATED = SN_UTIL + '/CANCEL_REASONS_UPDATED'
export const UTIL_FACILITIES_UPDATED = SN_UTIL + '/FACILITIES_UPDATED'
export const UTIL_ENUMERATIONS_UPDATED = SN_UTIL + '/ENUMERATIONS_UPDATED'
export const UTIL_FACILITY_LAT_LON_UPDATED = SN_UTIL + '/FACILITY_LAT_LON_UPDATED'
export const UTIL_STORES_INFORMATION_UPDATED = SN_UTIL + '/STORES_INFORMATION_UPDATED'