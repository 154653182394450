import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "overline" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { slot: "end" }
const _hoisted_4 = {
  class: "ion-padding",
  slot: "content"
}
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "overline" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { slot: "end" }
const _hoisted_10 = {
  key: 1,
  class: "ion-text-center"
}
const _hoisted_11 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_accordion = _resolveComponent("ion-accordion")!
  const _component_ion_accordion_group = _resolveComponent("ion-accordion-group")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, { icon: _ctx.close }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Other stores")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_ion_buttons, { slot: "end" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.sortByDistance }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.locationOutline,
                      color: _ctx.sortBy === 'distance' ? 'primary' : 'medium'
                    }, null, 8, ["icon", "color"])
                  ]),
                  _: 1
                }, 8, ["onClick"]),
                _createVNode(_component_ion_button, { onClick: _ctx.sortByName }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.textOutline,
                      color: _ctx.sortBy === 'name' ? 'primary' : 'medium'
                    }, null, 8, ["icon", "color"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_ion_searchbar, {
          modelValue: _ctx.queryString,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.queryString) = $event)),
          placeholder: _ctx.translate('Search store name'),
          onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => {_ctx.queryString = $event.target.value; _ctx.searchFacilities()}, ["enter"]))
        }, null, 8, ["modelValue", "placeholder"])
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_item, null, {
              default: _withCtx(() => [
                _createVNode(_component_ion_toggle, {
                  onClick: _ctx.toggleHideEmptyStock,
                  "label-placement": "start"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Hide facilities without stock")), 1)
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        (_ctx.storesWithInventory.length)
          ? (_openBlock(), _createBlock(_component_ion_accordion_group, { key: 0 }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.storesWithInventory, (store) => {
                  return (_openBlock(), _createElementBlock(_Fragment, {
                    key: store.storeCode
                  }, [
                    (_ctx.hasWeekCalendar(store))
                      ? (_openBlock(), _createBlock(_component_ion_accordion, {
                          key: 0,
                          value: store.storeCode
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_item, {
                              slot: "header",
                              lines: "inset"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                                  default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_1, _toDisplayString(store.storeCode), 1),
                                    _createElementVNode("h2", null, _toDisplayString(store.storeName), 1),
                                    (_ctx.hasWeekCalendar(store))
                                      ? (_openBlock(), _createBlock(_component_ion_note, {
                                          key: 0,
                                          color: store.isOpen ? '' : 'danger'
                                        }, {
                                          default: _withCtx(() => [
                                            _createTextVNode(_toDisplayString(store.hoursDisplay), 1)
                                          ]),
                                          _: 2
                                        }, 1032, ["color"]))
                                      : _createCommentVNode("", true),
                                    (typeof store.dist === 'number')
                                      ? (_openBlock(), _createElementBlock("p", _hoisted_2, _toDisplayString(Math.round(store.dist)) + " " + _toDisplayString(_ctx.translate("miles")), 1))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 2
                                }, 1024),
                                _createElementVNode("div", _hoisted_3, [
                                  _createVNode(_component_ion_note, { slot: "end" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.translate('ATP', { count: store.stock })), 1)
                                    ]),
                                    _: 2
                                  }, 1024)
                                ])
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("div", _hoisted_4, [
                              _createVNode(_component_ion_list, { lines: "none" }, {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekArray, (day) => {
                                    return (_openBlock(), _createBlock(_component_ion_item, { key: day }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_ion_label, null, {
                                          default: _withCtx(() => [
                                            _createElementVNode("p", null, _toDisplayString(_ctx.translate(day)), 1)
                                          ]),
                                          _: 2
                                        }, 1024),
                                        _createVNode(_component_ion_label, { slot: "end" }, {
                                          default: _withCtx(() => [
                                            (store[`${day}_open`] && store[`${day}_close`])
                                              ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.formatTime(store[`${day}_open`])) + " - " + _toDisplayString(_ctx.formatTime(store[`${day}_close`])), 1))
                                              : (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.translate("Closed")), 1))
                                          ]),
                                          _: 2
                                        }, 1024)
                                      ]),
                                      _: 2
                                    }, 1024))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["value"]))
                      : (_openBlock(), _createBlock(_component_ion_item, { key: 1 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                              default: _withCtx(() => [
                                _createElementVNode("p", _hoisted_7, _toDisplayString(store.storeCode), 1),
                                _createElementVNode("h2", null, _toDisplayString(store.storeName), 1),
                                (typeof store.dist === 'number')
                                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(Math.round(store.dist)) + " " + _toDisplayString(_ctx.translate("miles")), 1))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024),
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_ion_note, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.translate('ATP', { count: store.stock })), 1)
                                ]),
                                _: 2
                              }, 1024)
                            ])
                          ]),
                          _: 2
                        }, 1024))
                  ], 64))
                }), 128))
              ]),
              _: 1
            }))
          : (_openBlock(), _createElementBlock("div", _hoisted_10, [
              (_ctx.isRefreshing)
                ? (_openBlock(), _createBlock(_component_ion_spinner, {
                    key: 0,
                    name: "crescent"
                  }))
                : (_openBlock(), _createElementBlock("p", _hoisted_11, _toDisplayString(_ctx.translate("No inventory details found")), 1))
            ]))
      ]),
      _: 1
    })
  ], 64))
}