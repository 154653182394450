import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ion-text-wrap" }
const _hoisted_2 = { class: "ion-text-wrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_DxpShopifyImg = _resolveComponent("DxpShopifyImg")!
  const _component_ion_thumbnail = _resolveComponent("ion-thumbnail")!
  const _component_ion_badge = _resolveComponent("ion-badge")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.closeModal }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_icon, {
                      slot: "icon-only",
                      icon: _ctx.closeOutline
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Cancel items")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_list_header, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.translate("Summary")), 1)
              ]),
              _: 1
            }),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cancelledItems, (item, index) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: index,
                lines: index === _ctx.cancelledItems.length - 1 ? 'full' : 'inset'
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_thumbnail, { slot: "start" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_DxpShopifyImg, {
                        src: _ctx.getProduct(item.productId).mainImageUrl,
                        size: "small"
                      }, null, 8, ["src"])
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_label, { class: "ion-text-wrap" }, {
                    default: _withCtx(() => [
                      _createElementVNode("h2", null, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) ? _ctx.getProductIdentificationValue(_ctx.productIdentificationPref.primaryId, _ctx.getProduct(item.productId)) : _ctx.getProduct(item.productId).productName), 1),
                      _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.getProductIdentificationValue(_ctx.productIdentificationPref.secondaryId, _ctx.getProduct(item.productId))), 1),
                      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.getCancelReasonDescription(item.cancelReason)), 1),
                      (_ctx.isKit(item))
                        ? (_openBlock(), _createBlock(_component_ion_badge, {
                            key: 0,
                            color: "dark"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.translate("Kit")), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true)
                    ]),
                    _: 2
                  }, 1024),
                  _createVNode(_component_ion_note, { slot: "end" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.getProduct(item.productId).LIST_PRICE_PURCHASE_USD_STORE_GROUP_price ? _ctx.formatCurrency(_ctx.getProduct(item.productId).LIST_PRICE_PURCHASE_USD_STORE_GROUP_price, _ctx.order.currencyUom) : ""), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["lines"]))
            }), 128)),
            _createVNode(_component_ion_item, { lines: "full" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.translate("Total")), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_note, { slot: "end" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.formatCurrency(_ctx.orderTotal, _ctx.order.currencyUom)), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            (_ctx.isCancelationSyncJobEnabled && _ctx.isProcessRefundEnabled)
              ? (_openBlock(), _createBlock(_component_ion_item, {
                  key: 0,
                  lines: "full"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_label, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.translate("Estimated time to refund customer on Shopify")) + " ", 1),
                        _createElementVNode("p", null, _toDisplayString(_ctx.translate("Showing the next estimated time to sync cancelation to Shopify")), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_note, { slot: "end" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.runTimeDiff), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : (_ctx.isCancelationSyncJobEnabled)
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 1,
                    lines: "full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Estimated time to cancelation on Shopify")) + " ", 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Cancelation sync to Shopify is enabled. Refund processing is disabled.")), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_note, { slot: "end" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.runTimeDiff), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createBlock(_component_ion_item, {
                    key: 2,
                    lines: "full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.translate("Cancelation not syncing to Shopify")) + " ", 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.translate("Cancelation and refund sync to Shopify is not enabled.")), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
          ]),
          _: 1
        }),
        _createVNode(_component_ion_button, {
          class: "ion-margin",
          color: "danger",
          onClick: _ctx.cancelOrder
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.translate("Confirm Cancelation")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ], 64))
}